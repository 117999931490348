<template>
  <a-modal title="公告内容" width="1000px" v-model="visible" :footer="null" @close="visible = false">
    <div class="modal-content">
      <h1 class="basic-title">{{ rowItem.title }}</h1>
      <div class="basic-content" v-html="rowItem.comment"></div>
      <div class="basic-file">
        <h3>资料附件：</h3>
        <div class="basic-content">
          <div class="file-item-name" v-for="item in fileList" :key="item.uid" @click="handleDown(item.path)">
            {{ item.fileName }}
          </div>
        </div>
      </div>
      <template v-if="dataType !== 2">
        <h3 class="basic-tips">资质要求：</h3>
        <div class="basic-content" v-html="rowItem.ask"></div>
        <h3 class="basic-tips">资质需求文件：</h3>
        <div class="basic-content">{{ fileListName }}</div>
      </template>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'DetailModal',
  data () {
    return {
      visible: false,
      rowItem: {},
      dataType: 2
    }
  },
  computed: {
    fileList () {
      if (this.rowItem.fileUrl) {
        const data = JSON.parse(this.rowItem.fileUrl)
        if (this.dataType === 2) {
          return data.map((item, index) => ({
            path: item.filePath,
            uid: index,
            ...item
          }))
        } else {
          return data.map((item) => {
            const arr = item.path.split('.')
            return {
              ...item,
              fileName: item.fileName + '.' + arr[arr.length - 1]
            }
          })
        }
      }
      return []
    },
    fileListName () {
      const fileList = this.rowItem.fileList || []
      return fileList.map((item) => item.title).join('、')
    }
  },
  methods: {
    show (rowItem, type) {
      this.dataType = type
      if (type === 2) {
        this.rowItem = {
          ...rowItem.announcement,
          ...rowItem,
          comment: rowItem.info
        }
      } else {
        this.rowItem = { ...rowItem }
      }
      this.visible = true
    },
    handleDown (path) {
      window.open(path)
    }
  }
}
</script>

<style lang="less" scoped>
.modal-content {
  max-height: 500px;
  overflow-y: auto;
  .basic-title {
    text-align: center;
  }
  .basic-content {
    text-indent: 28px;
  }
  .basic-file {
  }
  .file-item-name {
    color: #40a9ff;
    cursor: pointer;
  }
}
</style>
